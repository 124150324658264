:root {
  --color-corporativo: #3DAE2B !important;
}

.lds-grid div {
  background: #3DAE2B !important;
}

.ticket-nuevo .btn-aceptar {
  color: #3DAE2B !important;
}


.Logotipo {
  display: block;
  width: 80%;
  height: auto;
  max-width: 400px;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 50px;
}

.LogotipoHome {
  display: block;
  width: 82%;
  height: auto;
  margin: auto;
  margin-top: 15%;
  margin-bottom: 10%;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid #8fb523 !important;
  outline: none;
}

.icono-linea-sms:focus,
.icono-linea-sms:active,
.icono-linea-sms:visited,
.icono-linea-sms:hover,
.icono-linea-sms:focus-visible {
  border: 1px solid #005fa6 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-llama:focus,
.icono-linea-llama:active,
.icono-linea-llama:visited,
.icono-linea-llama:hover,
.icono-linea-llama:focus-visible {
  border: 1px solid #8fb523 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-gb:focus,
.icono-linea-gb:active,
.icono-linea-gb:visited,
.icono-linea-gb:hover,
.icono-linea-gb:focus-visible {
  border: 1px solid #fc6424 !important;
  border-radius: 20px;
  outline: none;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid #005fa6 !important;
  outline: none;
}

.icono-linea:focus,
.icono-linea:active,
.icono-linea:visited,
.icono-linea:hover,
.icono-linea:focus-visible {
  border: 1px solid #005fa6 !important;
  border-radius: 20px;
  outline: none;
}

.num-contrato {
  color: var(--color-corporativo) !important;
}

.ul.list-unstyled li {

  border: 1px solid lightgray !important;

}

.nombre-tarifa {
  color: white !important;
}

.modal-header-tarifas .modal-title {
  color: white !important;
}

.modal-header-tarifas .icon-black-tarifas {
  color: white !important;
}




.btn-rojo-xl-tarifas {
  color: white !important;
}

.btn-rojo-xl-tarifas .icon-black {
  color: white !important;
}




ion-fab-button {
  --background: #b7f399;
  --background-activated: #87d361;
  --background-hover: #a3e681;
  --border-radius: 15px;
  --box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
  --color: black;

}

ion-fab-button {
  width: 150px;
  font-size: medium;

}